import React from 'react'
import { navigate } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { SubmitButton } from 'src/components/buttons/Primary'
import Heading from 'src/components/typography/Heading'
import Paragraph from 'src/components/typography/Paragraph'
import RadioGroup from 'src/components/forms/RadioGroup'
import SelectInput from 'src/components/forms/Select'
import useForm from 'src/hooks/useForm'
import { useVisitorApi } from 'src/hooks/useApi'
import useLocalizedRoutes from 'src/hooks/useLocalizedRoutes'
import { usePageContext } from 'src/context/PageContext'

const initialValues = {
    instrument: '',
    experience_level: 'none',
    instrument_disposal: ''
};

const ROW = 'flex items-center w-full py-2';
const COL = 'w-1/2';

export default function StudentAddDetailsForm() {
    const { t, i18n } = useTranslation();
    const {translatedInstruments, translatedExperienceLevel} = usePageContext();
    const { addStudentDetails } = useVisitorApi();
    const { editStudentAccount } = useLocalizedRoutes();

    const radioOptions = [
        {
            id: 1,
            value: 'owned',
            label: t('student.addDetails.haveAnInstrument.option1')
        },
        {
            id: 2,
            value: 'none',
            label: t('student.addDetails.haveAnInstrument.option2')
        },
    ];

    const {
        values,
        submit,
        isLoading,
        setValue,
    } = useForm({
        initialValues,
        onSubmit,
        onSuccess: onRegistrationCompleted,
        validationMessages: t('student.register.globalError'),
    });

    async function onSubmit() {
        return await addStudentDetails({
            ...values
        })
    }

    function onRegistrationCompleted() {
        navigate(editStudentAccount)
    }

    return (
        <form
            className="w-full md:w-25 px-6 lg:px-0 mt-4 md:mb-8 lg:my-10"
            onSubmit={submit}
        >
            <div className={ROW}>
                <div className={COL}>
                    <p className="text-xl text-dark">{t('student.addDetails.instrument')}</p>
                </div>
                <div className={COL}>
                    <SelectInput
                        isRequired
                        isFormSelect
                        id="instruments"
                        name="instruments"
                        data={translatedInstruments}
                        onChange={(item) => setValue('instrument', item.value)}
                    />
                </div>
            </div>
            <div className={ROW}>
                <div className={COL}>
                    <p className="text-xl text-dark">{t('student.addDetails.experienceLevel')}</p>
                </div>
                <div className={COL}>
                    <SelectInput
                        isRequired
                        isFormSelect
                        id="experience"
                        name="experience"
                        data={translatedExperienceLevel}
                        onChange={(item) => setValue('experience_level', item.value)}
                    />
                </div>
            </div>
            <div className="mt-12">
                <Heading type="caption">{t('student.addDetails.haveAnInstrument.title')}</Heading>
                <RadioGroup
                    name={'form-name'}
                    items={radioOptions}
                    onChange={(item) => setValue('instrument_disposal', item.target.value)}
                />
            </div>
            <div className="mt-8">
                <SubmitButton
                    isLoading={isLoading}
                >
                    {t('student.register.submit')}
                </SubmitButton>
            </div>
        </form>
    )
}
