import React from 'react'
import classNames from 'classnames'

const DEFAULT = "font-normal text-base whitespace-pre-wrap"
const NORMAL = "text-dark-600"
const INTRO_BLUE = "text-dark-blue-600 opacity-50 text-lg"
const INTRO_GRAY = "text-grey-600 opacity-50 text-lg"
const INTRO_DARK_GRAY = "text-grey-600 opacity-75 text-lg"

export default function Paragraph({children, type, className}) {
    const conditionalClasses = {
        [NORMAL]: !type,
        [INTRO_BLUE]: type === 'intro-blue',
        [INTRO_GRAY]: type === 'intro-gray',
        [INTRO_DARK_GRAY]: type === 'intro-gray-dark',
    }

    return <p className={classNames(DEFAULT, conditionalClasses, className)}>{children}</p>
}
