import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {camelCase, mapKeys} from 'lodash'

function isBadRequest({status}) {
    return status === 400;
}

function isUnprocessableEntity({status}) {
    return status === 422;
}

function isUnauthorizedEntry({status}) {
    return (status >= 400 && status <= 403) ;
}

function transformData(values) {
    return mapKeys(values, (value, key) => camelCase(key));
}

export default function useForm({ initialValues, onSubmit, onSuccess, onFail, validationMessages = [] }) {
    const { t } = useTranslation();
    const [values, setValues] = useState(initialValues || {});
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    function setValue(name, value) {
        setValues({
            ...values,
            [name]: value,
        });
    }

    function setValueObject(name, key, value) {
        setValues({
            ...values,
            [name]: {
                ...values[name],
                [key]: value
            },
        });
    }

    function clearValues(...names) {
        const clearedValues = names.reduce((clearedValues, name) => {
            clearedValues[name] = undefined;

            return clearedValues;
        }, {});

        setValues({ ...values, ...clearedValues });
    }

    function onChangeValue({ target }) {
        const { name, value } = target;

        setValues({
            ...values,
            [name]: value,
        });
    }

    function onChangeValueByName(name) {
        return value => {
            setValue(name, value);
        };
    }

    function setError(name, error) {
        setErrors({
            ...errors,
            [name]: error,
        });
    }

    async function submit(e) {
        e.preventDefault();
        setIsLoading(true);
        try {
            const res = await onSubmit(values, e);
            setErrors({});
            onSuccess && onSuccess(transformData(res?.data));
            setIsLoading(false);
        } catch (error) {
            handleErrors(error);
            setIsLoading(false);
        }
    }

    function handleErrors(error) {
        const { response } = error;
        const handledErrors = onFail && onFail(error);

        if (handledErrors) {
            setErrors(handledErrors);
            return;
        }

        if(response) {
            if (isUnprocessableEntity(response)) {
                if(response.data.message && !response.data.errors) {
                    setErrors({general: response.data.message})
                } else {
                    setErrorsFromValidationMessages(response.data);
                }
                return;
            } else if (isUnauthorizedEntry(response)) {
                setCustomErrors('notAuthorized', t('general.errors.notAuthorized'))
                return;
            }
        }

        setError('general', t('general.errors.somethingWentWrong'));
    }

    function setErrorsFromValidationMessages({ errors: originalErrors }) {
        const errors = transformData(originalErrors);

        for (const name in errors) {
            errors[name] = validationMessages[name] || errors[name];
        }

        setErrors(errors);
    }

    function setCustomErrors(key, value) {
        let obj = {};
        obj[key] = value;
        setErrors(obj);
    }

    return {
        values,
        initialValues,
        setValues,
        setValueObject,
        setValue,
        clearValues,
        onChangeValue,
        onChangeValueByName,
        errors,
        setErrors,
        setError,
        isLoading,
        submit,
        isBadRequest,
        isUnprocessableEntity,
        isUnauthorizedEntry,
    };
}
