import React, {useContext, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useVisitorApi} from 'src/hooks/useApi'
import {StringParam, useQueryParam} from 'use-query-params'
import {Context as ProfileContext} from 'src/context/ProfileContext'
import VisitorLayout from 'src/components/layout/VisitorLayout'
import Heading from 'src/components/typography/Heading'
import StudentAddDetailsForm from 'src/components/register/StudentAddDetailsForm'
import heroRegisterStudent from 'src/images/register/hero-register-student.jpg'
import {OutlinedButton} from "../../components/buttons/Primary";
import useLocalizedRoutes from "../../hooks/useLocalizedRoutes";
import Success from "../../components/forms/Success";
import { navigate } from 'gatsby'


export default function AddStudentDetails(props) {
    const {t} = useTranslation();
    const [token] = useQueryParam("verification_token", StringParam);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const {state: {profile}} = useContext(ProfileContext);
    const { verifyEmail, getMe} = useVisitorApi();
    const {addStudentDetails, home} = useLocalizedRoutes();
    const {location} = props;
    const {resendVerification} = useVisitorApi();
    const [successMsg, setSuccessMsg] = useState(false);

    async function resendEmailVerification() {
        const redirectUrl = location.origin + addStudentDetails;
        const res = await resendVerification(redirectUrl);
        if (res.status === 200) {
            setSuccessMsg(true);
            setTimeout(() => setSuccessMsg(false), 3000);
        }
    }

    async function verify() {
        try {
            const res = await verifyEmail({'verification_token': token})
            if (res.status === 200) {
                setIsSuccess(true);
            }
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (token) {
            verify().then(() => {
                getMe();
            })
        }
    }, [token]);

    return (
        <VisitorLayout {...props} hero={heroRegisterStudent} title={t('student.addDetails.title')}>
            {profile ?
            <div className="px-6 lg:px-0">
                {(profile.email_verified_at || (isSuccess && !isLoading)) &&
                <>
                    <Heading type='sub-title'>{t('student.addDetails.title')}</Heading>
                    <StudentAddDetailsForm/>
                </>
                }
                {!profile.email_verified_at && (!isSuccess && !isLoading) && (
                    <>
                        <Heading type='head-title-wrap'>{t('teacher.verifyEmail.error.title')}</Heading>
                        <div className="py-10">
                            <p>{t('teacher.verifyEmail.error.body')}</p>
                        </div>
                        {(profile ? profile.email_verified_at : false) &&
                        <OutlinedButton
                            onClick={() => resendEmailVerification()}>{t('general.verifyEmailResend')}</OutlinedButton>
                        }
                    </>
                )}
                {successMsg && <Success className="">{t('general.verifyEmail', {email: profile.email})}</Success>}
            </div> :
            <>
                <Heading type='head-title-wrap'>{t('teacher.verifyEmail.error.title')}</Heading>
                <div className="py-10">
                    {!isSuccess && !isLoading && (
                        <p>{t('general.accountNotfound')}</p>
                    )}
                </div>
                <OutlinedButton
                    onClick={() => navigate(home)}>{t('teacher.verifyEmail.error.button')}</OutlinedButton>
            </>
            }
        </VisitorLayout>
    )
}
