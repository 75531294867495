import React, { useContext } from 'react'
import { Context as ThemeContext } from 'src/context/ThemeContext'

export default function Dropdown(props) {
  const { state: { theme } } = useContext(ThemeContext)
  const fill = theme === 'inverse' ? "#23315F" : '#fff'

  return (
    <svg width={5} height={3} viewBox="0 0 5 3" {...props}>
      <title>{"Path"}</title>
      <path
        d="M2.085 2.798c.229.27.602.268.83 0L4.873.488C5.103.219 5.012 0 4.67 0H.33C-.013 0-.103.22.124.489l1.96 2.31z"
        fill={fill}
        fillRule="nonzero"
      />
    </svg>
  )
}

