import React from 'react'
import classNames from 'classnames'

export default function Label({ field, children, required, labelClasses, onClick }) {
    return (
        <label
            className={classNames('font-general text-xs md:text-sm text-dark mb-1 block', labelClasses)}
            onClick={onClick}
            htmlFor={field}
        >{required && '* '}{children}</label>
    )
}
