import React from 'react'
import classNames from 'classnames'

export default function Success({children, className}) {
    return (
        <div className={classNames("fixed bottom-0 left-1/2 transform -translate-x-1/2 mb-8 bg-dark-600 py-5 px-8 animation-fade-in animation-450ms animation-once animation-fill-forwards opacity-0", className)}>
            <span className="text-white">{children}</span>
        </div>
    )
}
